<template>
  <PageSection
    class="BrandsSettings"
    :page-title="$t('pages.settings.personal.trend_groups_sorting.headline')"
  >
    <div class="m-b-m">
      {{ $t('pages.settings.personal.trend_groups_sorting.sort_description') }}
    </div>

    <div v-loading="isLoading">
      <draggable v-model="computedTrendGroupsOrder" class="SortableTrendGroupsList">
        <div
          v-for="trendGroup in computedTrendGroupsOrder"
          :key="trendGroup.id"
          class="m-b-m p-a-s has-background-grey-lighter has-text-black has-text-weight-semibold is-flex is-aligned-middle SortableTrendGroupsList__item"
          data-testid="SortableTrendGroupsListItem"
        >
          <RoundAvatar
            :src="trendGroup.cover_image_url"
            :acronym-fallback="trendGroup.name"
            size="small"
            class="m-r-m"
          />

          <div class="m-r-a">
            {{ trendGroup.name }}
          </div>

          <v-icon
            class="m-r-m"
            size="lg"
            type="drag-handle"
          />
        </div>
      </draggable>
    </div>
  </PageSection>
</template>

<script>
import draggable from 'vuedraggable'

import RoundAvatar from '@hypefactors/shared/js/components/core/RoundAvatar.vue'
import VIcon from '@hypefactors/shared/js/components/core/VIcon.vue'

import PageSection from '@/components/core/PageSection.vue'

export default {
  components: {
    RoundAvatar,
    VIcon,
    PageSection,
    draggable
  },

  data () {
    return {
      isLoading: false,
      cancelToken: null,
      trendGroups: null
    }
  },

  computed: {
    computedTrendGroupsOrder: {
      get () {
        return this.trendGroups
      },
      set (value) {
        this.handleTrendGroupsOrderUpdate(value)
      }
    }
  },

  mounted () {
    this.fetchOrganisationTrendGroups()
  },

  methods: {
    fetchOrganisationTrendGroups () {
      this.cancelToken && this.cancelToken.cancel()

      this.cancelToken = this.$api.cancelToken()

      this.$api.get('trends/groups', {
        cancelToken: this.cancelToken.token
      })
        .then(response => {
          this.isLoading = false

          this.trendGroups = response.data
        })
        .catch((error) => {
          if (this.$api.isCancelToken(error)) {
            return
          }

          this.isLoading = false

          this.$displayRequestError(error, this.$t('errors.trend_groups_could_not_be_fetched'))
        })
    },

    handleTrendGroupsOrderUpdate (trendGroups) {
      this.isLoading = true

      let cachedTrendGroups = this.trendGroups

      this.trendGroups = trendGroups

      this.$api.post('trends/groups', {
        groups: trendGroups.map(b => b.id)
      })
        .catch((error) => {
          this.trendGroups = cachedTrendGroups

          this.$displayRequestError(error)
        })
        .finally(() => {
          cachedTrendGroups = null

          this.isLoading = false
        })
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';

.SortableTrendGroupsList {
  .SortableTrendGroupsList__item {
    cursor: grab;
  }

  .sortable-chosen {
    background-color: $grey-light !important;
    cursor: grabbing !important;
  }
}
</style>
